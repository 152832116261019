import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AwesomeButton } from "react-awesome-button";
import { elementVariant, elementVariantEarly } from '../helpers/animationVariants';

//components
import Process from './Process';

//libraries
import { motion } from "framer-motion";

const words = ['Web Apps', 'UI/UX', 'Branding', 'Integrations'];

export default function HomepageHeroSection() {
    const [wordIndex, setWordIndex] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 4000); // Change word every 4 seconds

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="relative isolate px-6 lg:px-8 bg-white mb-20">
            <div
                className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#a5dff0] to-[#a6e7ff] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-7xl pt-40 sm:pt-48 lg:pt-56 pb-8">
                <div className="text-center">
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <h1 className="my-6 sm:mb-6 text-4xl font-cooper-black-std tracking-tight text-vivid-cyan sm:text-6xl">
                            Big Web Labs
                        </h1>
                    </motion.div>
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <p className="mt-6 text-xl font-cooper-black-std leading-8 text-gray-900 sm:text-2xl">
                            Technology-First Design & Development Agency.
                        </p>
                    </motion.div>
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                        className="justify-center pt-6"
                    >
                        <p className="inline text-lg pb-4 font-cooper-black-std tracking-tight text-gray-900 sm:text-2xl" style={{ transition: 'all 0.75s ease' }}>
                            Specializing in&nbsp;
                        </p>
                        <p className="inline text-lg font-cooper-black-std tracking-tight text-vivid-cyan sm:text-2xl wordFadeInFadeOut">
                            {words[wordIndex]}
                        </p>
                    </motion.div>
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                    >
                        <Process />
                    </motion.div>
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariantEarly}
                        viewport={{ once: true, amount: 0 }}
                        className="my-10 flex items-center justify-center gap-x-6"
                    >
                        <AwesomeButton type="primary" ripple href="https://outlook.office365.com/book/consultations@bigweblabs.com/" target="_blank">Click me, let's chat!</AwesomeButton>
                        <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900 hover:no-underline hover:text-bright-magenta">
                            Reach out today <span aria-hidden="true">→</span>
                        </Link>
                    </motion.div>
                    <motion.div
                        initial="offscreen"
                        whileInView="onscreen"
                        variants={elementVariant}
                        viewport={{ once: true, amount: 0 }}
                        className="mx-auto max-w-2xl"
                    >
                        <p className="font-cooper-black-std pt-20 md:pt-28 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Featured</p>
                        <p className="mt-6 text-md sm:text-lg leading-8 text-gray-600">
                            Building applications for the future & next generation on the Web.
                        </p>
                    </motion.div>

                </div>
            </div>
            <div
                className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                aria-hidden="true"
            >
                <div
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#a5dff0] to-[#a6e7ff] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
        </div>
    )
}